<template>
<v-col cols="12" class="">
  <v-row>
    <v-col cols="12" class="">
      Hello AirP&P !
    </v-col>
  </v-row>
</v-col>
</template>

<script>
export default {

}
</script>

<style>

</style>